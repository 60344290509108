export default function Handler() {}

var id = null;

Handler.prototype.post = function (cbFunc) {
  if (typeof cbFunc === "function") {
    //console.log("Handler: Executing Handler without a delay");
    var execute = function () {
      // console.log("Handler: Executing Handler post now...");
      cbFunc();
      // console.log("Handler: Execution done.")
    };
  }
};

Handler.prototype.requestFrame = function (cbFunc) {
  if (typeof cbFunc === "function") {
    var execute = function () {
      cbFunc();
    };
    this.id = window.requestAnimationFrame(execute);
  }
};

Handler.prototype.postDelayed = function (cbFunc, millis) {
  if (typeof cbFunc === "function") {
    //console.log("Handler: post scheduled to " + millis + " milliseconds ahead.");
    var execute = function () {
      //console.log("Handler: Executing Handler post now...");
      cbFunc();
      // console.log("Handler: Execution done.")
    };
    this.id = setTimeout(execute, millis);
  }
};

Handler.prototype.cancel = function () {
  clearTimeout(id);
};
