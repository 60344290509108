import { withStyles } from "@material-ui/core/styles";

const UserInfoBoxStyle = (theme) => ({
  root: {
    width: "90%",
    position: "absolute",
    margin: "5px",
    padding: "10px",
    fontSize: "12px",
    zIndex: 9999,
  },
  contentGrid: {
    padding: "5px",
  },
  contentIcon: {},
});

export default withStyles(UserInfoBoxStyle);
