import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

class DSDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diagOpen: true,
    };
    this.handleClose = this.handleClose.bind(this);
  }
  handleClose() {
    this.setState({ diagOpen: false });
  }
  render() {
    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.diagOpen}
      >
        <DialogTitle>{this.props.diagTitle}</DialogTitle>
        <DialogContent>{this.props.diagContent}</DialogContent>
        <DialogActions>
          <Button autofocus onClick={this.handleClose} color="primary">
            Dispensar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default DSDialog;
