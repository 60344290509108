import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import Logo from "../images/logo-hori.png";

class DSAppBar extends React.Component {
  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <img alt="DriveSocial Logo" src={Logo} style={{ width: "200px" }} />
        </Toolbar>
      </AppBar>
    );
  }
}

export default DSAppBar;
