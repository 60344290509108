import React from "react";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import DRIVESOCIAL from "../etc/constants";
import Handler from "../etc/Handler";
mapboxgl.accessToken = "NO_TOKEN";
class DSMap extends React.Component {
  constructor(props) {
    super(props);
    this.vehicleSvg = null;
    this.getVehicleMarker = this.getVehicleMarker.bind(this);
    this.startTracking = this.startTracking.bind(this);
    this.toRadians = this.toRadians.bind(this);
    this.toDegrees = this.toDegrees.bind(this);
    this.getBearing = this.getBearing.bind(this);
    this.mapContainer = "";
    this.map = "";
    this.marker = "";
    this.state = {
      mapData: {
        latLng: {
          lat: 0.0,
          lng: 0.0,
        },
        bearing: 0,
        zoom: 1,
      },
      hash: null,
    };
    this.trackerTimer = null;
  }
  render() {
    return (
      <div>
        <div
          style={{ height: "91vh" }}
          ref={(el) => (this.mapContainer = el)}
        />
      </div>
    );
  }
  getVehicleMarker() {
    const el = document.createElement("div");
    el.className = "marker";
    if (this.vehicleSvg === null) {
      el.style.backgroundImage =
        "url(https://server.drivesocial.io/webclient-notlic/cache/car-878787.png)";
      el.style.width = "31px";
      el.style.height = "62px";
    } else {
      const data = btoa(this.vehicleSvg);
      //console.log(this.vehicleSvg);
      el.style.backgroundImage = `url(data:image/svg+xml;base64,${data})`;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.vehicleSvg, "text/xml");
      const root = xmlDoc.getElementsByTagName("svg");
      el.style.width = root[0].getAttribute("width") + "px";
      el.style.height = root[0].getAttribute("height") + "px";
    }

    return el;
  }
  createMarker() {
    const elem = this.getVehicleMarker();
    this.marker = new mapboxgl.Marker(elem, {
      anchor: "center",
    })
      .setLngLat([this.state.mapData.latLng.lng, this.state.mapData.latLng.lat])
      .addTo(this.map);
    this.marker.setRotation(this.state.mapData.bearing);
  }
  componentDidMount() {
    if (this.props.hash !== this.state.hash) {
      this.setState({ hash: this.props.hash });
    }
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "https://server.drivesocial.io/config/tilegl.json",
      center: [this.state.mapData.latLng.lng, this.state.mapData.latLng.lat],
      zoom: this.state.mapData.zoom,
    });
    this.startTracking();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.hash !== prevProps.hash) {
      this.setState({ hash: this.props.hash });
      this.startTracking();
    }
  }
  startTracking() {
    if (typeof this.props.hash !== "undefined" && this.props.hash !== null) {
      this.trackerTimer = setInterval(() => this.trackSOS(), 3000);
      this.trackSOS();
    }
  }
  toRadians(degrees) {
    return (degrees * Math.PI) / 180;
  }
  toDegrees(radians) {
    return (radians * 180) / Math.PI;
  }
  getBearing(lat1, lat2, lon1, lon2) {
    lat1 = this.toRadians(lat1);
    lat2 = this.toRadians(lat2);
    lon1 = this.toRadians(lon1);
    lon2 = this.toRadians(lon2);

    var y = Math.sin(lon2 - lon1) * Math.cos(lat2);
    var x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);

    var bearing = Math.atan2(y, x);
    bearing = this.toDegrees(bearing);
    return (bearing + 360) % 360;
  }
  smoothMove(
    fromLatLng,
    toLatLng,
    fromAngle,
    toAngle,
    starttime,
    millis,
    marker,
    id
  ) {
    let handler = new Handler();
    var fromLat = fromLatLng[0];
    var fromLng = fromLatLng[1];
    var toLat = toLatLng[0];
    var toLng = toLatLng[1];
    var t;
    var lng;
    var lat;
    var angle;
    var lastLat, lastLng;

    if (toAngle === 0 || typeof toAngle === "undefined") {
      toAngle = this.getBearing(fromLat, toLat, fromLng, toLng);
    }
    if (fromAngle < toAngle && fromAngle < 180 && toAngle > 180) {
      toAngle = toAngle - 360;
    }
    if (fromAngle < toAngle && fromAngle > 180 && toAngle < 180) {
      fromAngle = fromAngle - 360;
    }
    handler.requestFrame(function () {
      function run() {
        var nowtime = new Date();
        var elapsed = nowtime.getTime() - starttime;
        t = elapsed / millis;
        if (typeof lastLat == "undefined" || typeof lastLng == "undefined") {
          lastLat = lat;
          lastLng = lng;
        }

        lng = t * toLng + (1 - t) * fromLng;

        lat = t * toLat + (1 - t) * fromLat;

        angle = t * toAngle + (1 - t) * fromAngle;
        marker.setRotation(angle);
        if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
          marker.setLngLat([lng, lat]);
        }

        if (t < 1) {
          handler.requestFrame(run);
        }
      }
      run();
    });
  }
  trackSOS() {
    const uri = DRIVESOCIAL.API + "track/" + this.props.hash;
    axios
      .get(uri)
      .then((response) => {
        const sos = response.data;
        if (typeof this.props.callback === "function") {
          this.props.callback(sos);
        }
        this.vehicleSvg = sos.vehicleDetails.svgData;
        const userLocation = sos.userLocation;
        if (
          this.state.mapData.latLng.lat === 0.0 &&
          this.state.mapData.latLng.lng === 0.0
        ) {
          this.setState({
            mapData: {
              latLng: { lat: userLocation.lat, lng: userLocation.lng },
            },
          });
          this.createMarker();
          this.map.flyTo({
            center: [userLocation.lng, userLocation.lat],
            zoom: 13,
          });
        } else {
          this.smoothMove(
            [this.marker.getLngLat().lat, this.marker.getLngLat().lng],
            [userLocation.lat, userLocation.lng],
            this.marker.getRotation(),
            userLocation.bearing,
            new Date().getTime(),
            3000,
            this.marker
          );
        }
      })
      .catch((error) => {
        if (typeof this.props.callbackError === "function") {
          this.props.callbackError(error);
        }
        clearInterval(this.trackerTimer);
      });
  }
}

export default DSMap;
