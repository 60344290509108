import { Divider, Grid, Paper } from "@material-ui/core";
import React from "react";
import UserInfoBoxStyle from "../styles/UserInfoBoxStyle";
import PropTypes from "prop-types";
import WarningIcon from "@material-ui/icons/Warning";
import PersonIcon from "@material-ui/icons/Person";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import InfoIcon from "@material-ui/icons/Info";
class UserInfoBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sosDetails: {},
      userDetails: {},
      vehicleDetails: {},
      expanded: true,
    };
  }
  componentDidMount() {
    if (typeof this.props.details !== "undefined") {
      this.setState(
        {
          sosDetails: this.props.details.sosDetails,
          userDetails: this.props.details.userDetails,
        },
        () => {
          if (typeof this.props.details.vehicleDetails !== "undefined") {
            this.setState({
              vehicleDetails: this.props.details.vehicleDetails,
            });
          }
        }
      );
    }
  }
  render() {
    const { classes } = this.props;
    return this.renderContent(classes);
  }
  renderUserInfo(classes) {
    return (
      <>
        <Grid item xs={3} className={classes.contentIcon}>
          <PersonIcon />
        </Grid>
        <Grid item xs={6}>
          <b>{this.state.userDetails.fullname}</b>
        </Grid>
      </>
    );
  }
  renderVehicleInfo(classes) {
    return (
      <>
        <Grid item xs={3} className={classes.contentIcon}>
          {this.state.vehicleDetails.type === "car" && <DirectionsCarIcon />}
          {this.state.vehicleDetails.type === "bike" && <DirectionsBikeIcon />}
          {this.state.vehicleDetails.type === "motorcycle" && (
            <MotorcycleIcon />
          )}
        </Grid>
        <Grid item xs={3}>
          {this.state.vehicleDetails.model}
        </Grid>
        <Grid item xs={3}>
          <b>{this.state.vehicleDetails.lic_plate}</b>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        {this.state.vehicleDetails.unique_features !== "" && (
          <>
            <Grid item xs={3} className={classes.contentIcon}>
              <InfoIcon />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                fontWeight: "light",
                fontSize: "13px",
                color: "#333",
              }}
            >
              {this.state.vehicleDetails.unique_features}
            </Grid>
          </>
        )}
      </>
    );
  }
  renderExtraInfo(classes) {
    return (
      <>
        <Grid item xs={3} className={classes.contentIcon}>
          <WarningIcon style={{ color: "red" }} />
        </Grid>
        <Grid item xs={6}>
          SOS em curso desde às{" "}
          <b>
            {this.sosFriendlyTime(this.state.sosDetails.requestedOn, "hours")}
          </b>{" "}
          do dia{" "}
          <b>
            {this.sosFriendlyTime(this.state.sosDetails.requestedOn, "day")}
          </b>
        </Grid>
      </>
    );
  }
  sosFriendlyTime(unixtime, what) {
    const date = new Date(unixtime * 1000);
    if (what === "hours") {
      return date.getHours() + "h" + date.getMinutes() + "m";
    } else if (what === "day") {
      return (
        date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      );
    }
  }
  renderContent(classes) {
    return (
      <Paper elevation={1} variant="outlined" square className={classes.root}>
        <Grid spacing={1} container className={classes.contentGrid}>
          {this.renderUserInfo(classes)}
        </Grid>
        <Divider />
        <Grid container className={classes.contentGrid}>
          {this.renderVehicleInfo(classes)}
        </Grid>
        <Divider />

        <Grid spacing={1} container className={classes.contentGrid}>
          {this.renderExtraInfo(classes)}
        </Grid>
      </Paper>
    );
  }
}
UserInfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default UserInfoBoxStyle(UserInfoBox);
