import { createMuiTheme } from "@material-ui/core";
import { pink } from "@material-ui/core/colors";

const themeConf = createMuiTheme({
  palette: {
    primary: {
      main: pink[900],
    },
  },
});
export default themeConf;
