import React from "react";
import "./App.css";
import DSAppBar from "./components/DSAppBar";
import { ThemeProvider } from "@material-ui/core/styles";
import themeConf from "./etc/themeConf";
import "fontsource-roboto";
import DSMap from "./components/DSMap";
import UserInfoBox from "./components/UserInfoBox";
import DSDialog from "./components/DSDialog";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: null,
      details: null,
      error: false,
      errorCause: "",
    };
    this._mapCallback = this._mapCallback.bind(this);
    this._renderContent = this._renderContent.bind(this);
    this._renderMap = this._renderMap.bind(this);
    this._renderLoading = this._renderLoading.bind(this);
    this._mapCallbackError = this._mapCallbackError.bind(this);
  }
  render() {
    return (
      <ThemeProvider theme={themeConf}>
        <DSAppBar />
        {this._renderContent()}
      </ThemeProvider>
    );
  }
  componentDidMount() {
    const hash = window.location.href.split("?")[1];
    if (typeof hash !== "undefined" && hash !== "") {
      this.setState({ hash: hash });
    } else {
      this.setState({ state: "errorForm" });
    }
  }
  _renderMap() {
    return (
      <DSMap
        hash={this.state.hash}
        callback={this._mapCallback}
        callbackError={this._mapCallbackError}
      />
    );
  }
  _mapCallbackError(error) {
    if (typeof error !== "undefined") {
      this.setState({ error: true, error: error });
    }
  }
  _mapCallback(cbData) {
    this.setState({ details: cbData });
  }
  _renderContent() {
    if (this.state.error) {
      return this._renderError();
    }
    if (this.state.hash === null) {
      return this._renderError();
    } else if (this.state.hash !== null) {
      if (this.state.details === null) {
        return this._renderMap();
      } else {
        return (
          <>
            <UserInfoBox details={this.state.details} /> {this._renderMap()}
          </>
        );
      }
    }
  }
  _renderError() {
    if (typeof this.state.errorCause !== "undefined") {
      let friendlyError =
        "Código de compartilhamento desconhecido, ou tempo de acompanhamento expirado.";

      return (
        <>
          {this._renderMap()}
          <DSDialog diagTitle="Erro" diagContent={friendlyError} />
        </>
      );
    } else {
      return this._renderMap();
    }
  }
  _renderLoading() {
    return "Carregando...";
  }
}

export default App;
